import React from 'react'
import { Link } from 'gatsby'

import { AwesomeButton } from 'react-awesome-button'
import 'react-awesome-button/dist/styles.css'
import Layout from '../components/Layout'
import Head from '../components/Head'
import './404.scss'

const PageNotFound = () => {
  return (
    <Layout isNavVisible={false} noActive={true}>
      <Head title="Page Not Found" />
      <div id="pageNotFound" className="row flex-grow-1">
        <div className="col-12 col-md-8 d-flex justify-content-center flex-column">
          <div className="d-flex flex-column">
            <h1 class="pageNotFound">404.</h1>
            <h1 className="mb-5">Page Not Found.</h1>
          </div>
          <h2 className="mb-3">You're probably lost.</h2>
          <div className="d-flex">
            <h2 className="mr-2 mr-sm-3">It's time to go</h2>
            <Link to="/">
              <AwesomeButton style={{ fontWeight: 500 }} type="primary" ripple>
                home.
              </AwesomeButton>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
